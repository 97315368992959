import { Injectable } from '@angular/core';
import { PageRequestInput } from '@gqlSchema';

@Injectable({
  providedIn: 'root',
})
export abstract class PaginatorService {
  public defaultPageSize = 10;

  public pageRequest: PageRequestInput = {
    offset: 0,
    limit: this.defaultPageSize,
  };

  public pageSizes = [5, 10, 25, 50];
  public pageIndex: number = 0;
  public totalCount: number = 0;
  public paginationLoading: boolean = false;

  abstract fetchMoreData(data: any): void;

  public hasMoreData() {
    return !!(
      (this.pageIndex + 1) * this.pageRequest.offset >=
      this.totalCount
    );
  }

  public setPagination(
    offset: number = 0,
    limit: number = this.defaultPageSize,
  ) {
    this.pageRequest.offset = offset;
    this.pageRequest.limit = limit;
  }

  public resetPagination(
    offset: number = 0,
    limit: number = this.defaultPageSize,
  ) {
    this.setPagination(offset, limit);
  }

  public applyPager(data: any = null) {
    if (!this.hasMoreData) return;

    this.pageRequest.offset = this.pageRequest.limit * (this.pageIndex + 1);
    this.pageIndex++;
    this.paginationLoading = true;
    this.fetchMoreData(data);
  }

  public getPrevioutPage(data: any = null) {
    if (!this.pageIndex) return;

    this.pageRequest.offset = this.pageRequest.limit * (this.pageIndex - 1);
    this.pageIndex--;
    this.paginationLoading = true;
    this.fetchMoreData(data);
  }

  getNextPage(data: any = null) {
    this.applyPager();
  }
}
